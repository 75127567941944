import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import styled from "styled-components/macro";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Grid, IconButton, InputAdornment } from "@mui/material";

import {
  TextField,
  AutocompleteMultiSelect,
  FileUpload,
  ComponentHeader,
} from "../../../../components";
import { TextFourteenBlack, colors } from "../../../css components/Style";
import BrandDetailsDialog from "../../brand details components/BrandDetailsDialog";
import { titleCase } from "change-case-all";

const ImageDiv = styled.div`
  width: 286px;
  height: 120px;
  border: 1px solid #d6cbcb;
  border-radius: 10px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 10px;
`;

export default function FormOne({
  categories,
  formikProps,
  editState,
  brandIdentity,
  component,
  brandDetails,
  setBrandDetails,
}) {
  const onImageError = (e) => {
    e.target.src =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png";
  };

  const { creditDetails } = useSelector((state) => state.memberDetails);
  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader
          title="Campaign Details"
          subtitle={
            !formikProps.values.campaignID
              ? "Setup your campaign details"
              : titleCase(formikProps.values?.campaign_name?.value)
          }
          // icons={[
          //   <Chip
          //     background={colors.purple}
          //     color="#fff"
          //     label={formikProps?.values?.Intersection_Audience}
          //   />,
          //   <Chip
          //     background={colors.purple}
          //     color="#fff"
          //     label={formikProps?.values?.cpm}
          //   />,
          // ]}
        />
      </Grid>

      {!!formikProps.values.previewImage && (
        <Fragment>
          <Grid item xs={12}>
            <ImageDiv>
              <Image
                src={formikProps.values.previewImage}
                alt="!"
                onError={onImageError}
              />
            </ImageDiv>
          </Grid>
        </Fragment>
      )}

      {(component === "superadmin" || component === "accepted") && (
        <Fragment>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Brand Name"
              name="brand_name"
              placeholder="Brand Name"
              disabled={!editState}
              value={brandDetails?.data?.brandName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconButton
                      onClick={() =>
                        setBrandDetails((prevVal) => ({
                          ...prevVal,
                          state: true,
                        }))
                      }
                      style={{ padding: "0" }}
                    >
                      <Info style={{ color: colors.purple }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Fragment>
      )}

      <Grid item xs={6}>
        <TextField
          required
          name="campaign_name.value"
          placeholder="Campaign Name"
          label="Campaign Name"
          formikProps={formikProps}
          disabled={!editState}
        />

        {!brandIdentity && (
          <FileUpload
            required
            style={{ paddingTop: "24px" }}
            disabled={!editState}
            name="previewImage"
            formikProps={formikProps}
            placeholder="Select Preview Image"
            allowedExtension=".jpg,.jpeg,.png,.gif"
            value={formikProps.values.previewImage
              ?.split("/")
              .pop()
              .slice(
                formikProps.values?.previewImage
                  ?.split("/")
                  .pop()
                  .indexOf("_") + 1
              )}
            folderPath={"public/preview_images/" + new Date().getTime() + "_"}
          />
        )}
      </Grid>

      {(!brandIdentity ||
        component === "accepted" ||
        component === "superadmin") && (
        <Fragment>
          <Grid item xs={6}>
            <TextField
              name="about.value"
              placeholder="About Campaign"
              multiline
              label="About Campaign"
              rows={4}
              formikProps={formikProps}
              disabled={!editState}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              name="destination_url.value"
              formikProps={formikProps}
              placeholder="Campaign Destination URL"
              disabled={!editState}
              label="Campaign Destination URL"
            />
          </Grid>
        </Fragment>
      )}

      <Grid item xs={3}>
        <DateTimePicker
          name="start_duration.value"
          formikProps={formikProps}
          label="Start Duration"
          minDateTime={new Date()}
          value={formikProps.values.start_duration.value}
          disabled={!editState}
          onChange={(newValue) => {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              start_duration: setValue(prevVal.start_duration),
              startTime: newValue,
            }));

            function setValue(val) {
              val.value = newValue;
              return val;
            }
          }}
          renderInput={(params) => (
            <TextField
              required
              autoComplete="off"
              label="Start Duration"
              disabled={!editState}
              name="start_duration.value"
              formikProps={formikProps}
              {...params}
            />
          )}
        />
      </Grid>

      <Grid item xs={3}>
        <DateTimePicker
          required
          name="end_duration.value"
          formikProps={formikProps}
          label="End Duration"
          disabled={!editState}
          value={formikProps.values.end_duration.value}
          minDate={
            formikProps.values.start_duration.value
              ? new Date(formikProps.values.start_duration.value).setDate(
                  new Date(formikProps.values.start_duration.value).getDate()
                )
              : new Date()
          }
          minTime={
            new Date(formikProps.values.end_duration.value).setHours(
              0,
              0,
              0,
              0
            ) ===
              new Date(formikProps.values.start_duration.value).setHours(
                0,
                0,
                0,
                0
              ) &&
            new Date(
              new Date(formikProps.values.start_duration.value).getTime() +
                30 * 60 * 1000
            )
          }
          onChange={(newValue) => {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              end_duration: setValue(prevVal.end_duration),
              endTime: newValue,
            }));
            function setValue(val) {
              val.value = newValue;
              return val;
            }
          }}
          renderInput={(params) => (
            <TextField
              required
              autoComplete="off"
              label="End Duration"
              disabled={!editState}
              name="end_duration.value"
              formikProps={formikProps}
              {...params}
            />
          )}
        />
      </Grid>

      {/* <Grid item xs={6}>
        <TextField
          required
          disabled={!editState}
          name="volume.value"
          placeholder="Desired Volume"
          formikProps={formikProps}
          label="Desired Volume"
          inputProps={{ type: "number" }}
        />

        {editState && component === "" && (
          <TextFourteenBlack style={{ color: "#333", opacity: "0.8" }}>
            NOTE: Desired Volume should be less than or equal to the credits
            {`(${creditDetails.coins})`}
          </TextFourteenBlack>
        )}
      </Grid> */}

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          name="monthly_active_user.value"
          formikProps={formikProps}
          placeholder="Monthly Active Users"
          label="Monthly Active Users"
          inputProps={{ type: "number" }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          label="Preferred Business Categories"
          name="category.value"
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "name",
            displayLabel: "name",
          }}
          disabled={!editState}
          options={categories.filter((i) => i.id !== 31)}
          placeholder="Preferred Business Categories"
          onChange={(_, arr, reason, option) =>
            handleChange(arr, option, reason, "category.value", "name")
          }
        />
      </Grid>
      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />
    </Fragment>
  );

  function handleChange(arr, { option }, reason, name, displayName) {
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValuesOfDropDown(prevVal[objectName]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val.value];

      if (reason === "removeOption") {
        if (typeof option === "object") {
          values = values.filter((val) => val !== option[displayName]);
        }

        if (typeof option === "string") {
          values.pop();
        }
      }
      if (reason === "selectOption") {
        values.push(option[displayName]);
      }

      return {
        ...val,
        value: values,
      };
    }
  }
}
